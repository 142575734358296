// UI框架
import React from 'react';
import { connect } from 'react-redux';
import {AuthComponent} from 'publicHOC';
// UI组件
import { Col, Row, message, Button } from 'antd';
import {Icon, LabelSelect} from 'libs';
// 业务组件
import {LangConsumer} from 'utils/LangProvider';
import {bindLifecycle} from 'utils/bindLifecycle';
import * as mapDispatchToProps from '../../../../store/views/AIConfig/IVSTrafficIllegal/IVSTrafficIllegalRegion/controller';
import { clone, transToChannelName } from '../../../../common';
import TrafficIllegalRegionConfigurationPlugin from './TrafficIllegalRegionConfigurationPlugin';
import TrafficIllegalRegionConfigurationConfigForm from './TrafficIllegalRegionConfigurationConfigForm';
import WebApp from '../../../../webapp';
import RPC from 'api';
// 样式
import './index.less';

const _name_ = 'TrafficIllegalRegionConfiguration';
const ButtonGroup = Button.Group;

// 页面需要的数据
const mapStateToProps = (state) => {
    const { IVSTrafficIllegalRegionConfig, publicState } = state;

    return { IVSTrafficIllegalRegionConfig, publicState };
};
//数据模板，用于新增
let areaData = {
    Enable: true,
    Name: 'IPS-',
    DetectRegion: [],
    Number: 0,
    SceneDetectRegionType: [],
};
const areaNameList = {
    'TrafficIllegal': 'IPS-',
    'RoadEvent': 'RE-',
    'TrafficThrow': 'D-',
};

class TrafficIllegalRegionConfigurationComponent extends AuthComponent {
    /** 用来存放组件内状态 */
    constructor (props) {
        super(props);
        this.ComponentName = _name_;
        this.plugin = React.createRef();
        this.realForm = null;
        this.state = {
            curPreset: WebApp.DeviceType.indexOf('SD') > -1 ? 1 : 0, //当前预置点
            selectRow: 0, //表格当前选中行
            selectNumber: 0, //表格当前选中的区域号
        };
        this.completeDraw = true; //是否完成了图形绘制
        this.channelToPresetObj = {}; //用于存放每个通道中开启违停抓拍大类的预置点
    }

    componentDidMount () {
        // 功能入口
        let { init, $t, IVSTrafficIllegalRegionConfig : {vm}, realPlanType, ClassType, currentPlanID, refreshforChannel } = this.props;
        const sceneType = ClassType || 'TrafficIllegal';
        areaData.Name = areaNameList[sceneType];
        //主要用于区分同一个预置点下各个区域属于哪个智能
        areaData.SceneDetectRegionType = [sceneType];
        init(realPlanType, sceneType, currentPlanID) //初始化
            .then(() => {
                for (let i = 0; i < vm.VGlobal.length; i++) {
                    this.channelToPresetObj[i] = [];
                    for (let item in vm.VGlobal[i]) {
                        let itemVal = vm.VGlobal[i][item];
                        if (realPlanType === 'globalPlan') { // 全局方案
                            if (/Scene/.test(item) && (itemVal.Type === sceneType || (itemVal.TypeList && itemVal.TypeList.indexOf(sceneType) > -1)) && itemVal.PtzPresetId === 1000) {
                                this.channelToPresetObj[i].push(itemVal.PtzPresetId);
                            }
                        } else if (realPlanType === 'presetPlan') { //预置点方案
                            if (/Scene/.test(item) && (itemVal.Type === sceneType || (itemVal.TypeList && itemVal.TypeList.indexOf(sceneType) > -1)) && (itemVal.PtzPresetId !== 1000 && itemVal.PtzPresetId !== 0)) {
                                this.channelToPresetObj[i].push(itemVal.PtzPresetId);
                            }
                        }
                    }
                }

                let channelOptions = []; //通道项
                let presetOptions = []; //预置点项

                for (let item in this.channelToPresetObj) {
                    if (this.channelToPresetObj[item] instanceof Array && this.channelToPresetObj[item].length > 0) {
                        channelOptions.push({value: item - 0, text: transToChannelName($t, item - 0)});
                    }
                }
                refreshforChannel().then(() => {
                    if (this.channelToPresetObj && this.channelToPresetObj[vm.CurChannel] instanceof Array) {
                        for (let i = 0; i < this.channelToPresetObj[vm.CurChannel].length; i++) {
                            if (this.channelToPresetObj[vm.CurChannel][i] !== 1000) {
                                presetOptions.push({value: this.channelToPresetObj[vm.CurChannel][i], text: this.findPresetName(this.channelToPresetObj[vm.CurChannel][i])});
                            }
                        }
                    }
                }).finally(() => {
                    this.setState({
                        channelOptions,
                        presetOptions
                    });
                });
                if (WebApp.DeviceType.indexOf('SD') > -1) {
                    this.initGoToPreset();
                }
            })
            .catch(() => {
                message.error($t('com.Operateingfailure'));
            });
    }

    initGoToPreset = () => {
        let {IVSTrafficIllegalRegionConfig : {vm}, realPlanType} = this.props;
        let curPreset = 1;
        if (this.channelToPresetObj && this.channelToPresetObj[vm.CurChannel] instanceof Array) {
            for (let i = 0; i < this.channelToPresetObj[vm.CurChannel].length; i++) {
                curPreset = this.channelToPresetObj[vm.CurChannel][i];
                break;
            }
        }
        this.setState({curPreset}, () => {
            realPlanType !== 'globalPlan' && this.goToPreset(curPreset); //球机需要转动云台到对应的预置点(全局方案为1000预置点，不需要转云台)
            this.reOpenPlugin();
        });
    }

    componentDidActivate () {
        let {realPlanType} = this.props;
        this.reOpenPlugin();
        if (WebApp.DeviceType.indexOf('SD') > -1 && realPlanType !== 'globalPlan') { //全局方案为1000预置点，不需要转云台
            let {curPreset} = this.state;
            this.goToPreset(curPreset); //球机需要转动云台到对应的预置点
        }
    }

    componentWillUnmount () {
        // const { clear } = this.props;
        // clear(); //清理退出
        this.closePlugin();
    }

    componentWillUnactivate () {
        // const { clear } = this.props;
        // clear(); //清理退出
        this.closePlugin();
    }

    /**
     * 转动到对应预置点
     * @param {Number} goPreset 需要转到的预置点
     */
    goToPreset = (goPreset) => {
        let {IVSTrafficIllegalRegionConfig : {vm}} = this.props;
        RPC.PTZManager('gotoPreset', vm.CurChannel, {'index': goPreset}).catch(err => {console.log(err);});
    }

    // 关闭插件,供tabpanel切换时使用
    closePlugin = () => {
        if (this.plugin.current) {
            this.plugin.current.leave();
        }
    }

    // 重新打开插件
    reOpenPlugin = () => {
        let {IVSTrafficIllegalRegionConfig : {vm}} = this.props;
        if (this.plugin.current) {
            this.plugin.current.addPluginEvent();
            this.plugin.current.open(1, vm.CurChannel, 0);
            this.plugin.current.cover();
            setTimeout(() => {
                this.plugin.current.drawArea();
            }, 200);
        }
    }
    /**
     * 切换通道
     * @param {Number} channel 通道号
     */
    changeChannel = (channel) => {
        let {change, IVSTrafficIllegalRegionConfig : {vm}, $t, refreshforChannel} = this.props;
        vm.CurChannel = channel;
        change(vm)
            .then(() => {
                let presetOptions = [];
                refreshforChannel().then(() => {
                    if (this.channelToPresetObj && this.channelToPresetObj[vm.CurChannel] instanceof Array) {
                        for (let i = 0; i < this.channelToPresetObj[vm.CurChannel].length; i++) {
                            presetOptions.push({value: this.channelToPresetObj[vm.CurChannel][i], text: this.findPresetName(this.channelToPresetObj[vm.CurChannel][i])});
                        }
                    }
                }).finally(() => {
                    this.setState({
                        presetOptions
                    });
                });
                this.initGoToPreset();
                let curGlobalConfig = this.findCurGlobalConfig();
                if (this.realForm) {
                    this.realForm.handleRowClick(this.findTabData(curGlobalConfig)[0], 0);
                }
            })
            .catch(() => {
                message.error($t('com.Operateingfailure'));
            });
    }
    findTabData = (curGlobalConfig) => {
        let {IVSTrafficIllegalRegionConfig : {vm:{sceneType}}} = this.props;
        let tableData = [];
        if (curGlobalConfig && curGlobalConfig.Detail && curGlobalConfig.Detail.DetectRegions) {
            for (var i = 0; i < curGlobalConfig.Detail.DetectRegions.length; i++) {
                let itemValCopy = curGlobalConfig.Detail.DetectRegions[i];
                //新增字段类型区分
                if (itemValCopy.SceneDetectRegionType === undefined || (itemValCopy.SceneDetectRegionType !== undefined && itemValCopy.SceneDetectRegionType[0] === sceneType)) {
                    tableData.push(clone(itemValCopy));
                }
            }
        }
        return tableData;
    }
    /**
     * 切换预置点
     * @param {Number} preset 预置点
     */
    changePreset = (preset) => {
        this.setState({curPreset : preset}, () => {
            this.reDrawArea();
            this.goToPreset(preset); //球机需要转动云台到对应的预置点
        });
    }
    /**
     * 切换下拉状态
     * @param {Boolean} isVisible 是否显示
     */
    dropdownVisibleChange = (isVisible) => {
        if (isVisible) { // 显示下拉
            if (this.plugin.current) {
                this.plugin.current.hide();
                this.plugin.current.showVideoDefaultImage(true);
            }
        } else {
            if (this.plugin.current) {
                this.plugin.current.cover();
                this.plugin.current.showVideoDefaultImage(false);
            }
        }
    }
    /**
     * 查找当前global配置
     * @return {Object} curData 当前global配置
     */
    findCurGlobalConfig = () => {
        let {curPreset} = this.state;
        let {IVSTrafficIllegalRegionConfig : {vm, vm:{sceneType}}} = this.props;
        let curData = {};
        for (let item in vm.VGlobal[vm.CurChannel]) {
            let itemVal = vm.VGlobal[vm.CurChannel][item];
            if (/Scene/.test(item) && (itemVal.Type === sceneType || (itemVal.TypeList && itemVal.TypeList.indexOf(sceneType) > -1)) && itemVal.PtzPresetId === curPreset) {
                curData = vm.VGlobal[vm.CurChannel][item];
            }
        }
        return curData;
    }
    /**
     * 查找当前Calibrate配置
     * @return {Object} curData 当前Calibrate配置
     */
    findCurCalibrateConfig = () => {
        let {curPreset} = this.state;
        let {IVSTrafficIllegalRegionConfig : {vm}, change} = this.props;
        let curData = {};
        let CalibratePtzPresetIDArr = [];
        if (vm.VideoAnalyseCalibrate[vm.CurChannel] instanceof Array) {
            for (let i = 0; i < vm.VideoAnalyseCalibrate[vm.CurChannel].length; i++) {
                let itemVal = vm.VideoAnalyseCalibrate[vm.CurChannel][i];
                CalibratePtzPresetIDArr.push(itemVal.PtzPresetID);
                if (itemVal.PtzPresetID === curPreset) {
                    curData = itemVal;
                    break;
                }
            }
        }
        if (CalibratePtzPresetIDArr.indexOf(curPreset) === -1) {
            curData = clone(vm.TemplateCalibrate);
            curData.PtzPresetID = curPreset;
            if (vm.VideoAnalyseCalibrate[vm.CurChannel] instanceof Array) {
                vm.VideoAnalyseCalibrate[vm.CurChannel].push(curData);
            } else {
                vm.VideoAnalyseCalibrate[vm.CurChannel] = [];
                vm.VideoAnalyseCalibrate[vm.CurChannel].push(curData);
            }
            change(vm);
        }
        return curData;
    }
    /**
     * 设置当前选中行
     * @param {Number} selectRow 选中行号
     * @param {Number} selectNumber 选中区域号
     */
    setCurAreaRow = (selectRow, selectNumber) => {
        this.setState({selectRow, selectNumber}, () => {
            if (this.plugin.current) {
                this.plugin.current._ResetAreaState();
                this.plugin.current._SelectCurArea();
            }
        });
    }
    /**
     * 在视频中显示区域图形
     * @param {Number} index 区域行号
     * @param {Boolean} enable 是否显示
     */
    showAreaInPlugin = (index, enable) => {
        if (this.plugin.current) {
            this.plugin.current._ShowArea(index, enable);
        }
    }
    /**
     * 添加区域
     */
    clickAddArea = () => {
        if (!this.completeDraw) {
            return;
        }
        let {$t} = this.props;
        let {curPreset} = this.state;
        let tmpAreaData = clone(areaData);
        if (this.plugin.current) {
            this.completeDraw = false;
            this.plugin.current._ResetAreaState();
            let number = this.findAreaNumber();
            tmpAreaData.Number = number;
            tmpAreaData.Name = $t(tmpAreaData.Name) + (number + 1);
            this.plugin.current.drawNewArea(tmpAreaData);
        }
    }
    /**
     * 查找区域的Number供新增的区域使用(要求：当前通道当前预置点下，不允许有重名)
     * @return {Number} totalAreaArr[0] 查找到的第一个没有的Number
     */
    findAreaNumber = () => {
        let {IVSTrafficIllegalRegionConfig : {caps}} = this.props;
        let currentAreaArr = [];
        let totalAreaArr = [];
        let curGlobalConfig = this.findCurGlobalConfig();
        for (let i = 0; i < curGlobalConfig.Detail.DetectRegions.length; i++) {
            currentAreaArr.push(curGlobalConfig.Detail.DetectRegions[i].Number);
        }
        for (let i = 0; i < 20; i++) {
            totalAreaArr.push(i);
        }
        if (currentAreaArr.length === 0) {
            return 0;
        } else {
            for (let i = 0; i < currentAreaArr.length; i++) {
                for (let j = 0; j < totalAreaArr.length; j++) {
                    if (totalAreaArr[j] === currentAreaArr[i]) {
                        totalAreaArr.splice(j, 1);
                        j--;
                    }
                }
            }
            return totalAreaArr[0];
        }
    }
    /**
     * 新增区域（子组件触发）
     * @param {Object} addData 添加数据
     * @param {Boolean} flag 图形绘制完成
     */
    addAreaData = (addData, flag) => {
        let {change, IVSTrafficIllegalRegionConfig : {vm}} = this.props;
        let curGlobalConfig = this.findCurGlobalConfig();
        let isReDraw = false;
        this.completeDraw = flag;
        for (let i = 0; i < curGlobalConfig.Detail.DetectRegions.length; i++) {
            if (curGlobalConfig.Detail.DetectRegions[i].Number === addData.Number) {
                isReDraw = true;
                break;
            }
        }
        if (isReDraw) {
            this.modifyOrSelectedAreaData(addData);
        } else {
            curGlobalConfig.Detail.DetectRegions.push(addData);
            change(vm);
            let addRow = this.findTabData(curGlobalConfig).length - 1;
            if (this.realForm) {
                this.realForm.handleRowClick(addData, addRow);
            }
        }
    }
    /**
     * 修改/选中区域图形
     * @param {Object} modifyOrSelectedData 修改数据
     */
    modifyOrSelectedAreaData = (modifyOrSelectedData) => {
        let {change, IVSTrafficIllegalRegionConfig : {vm, vm:{sceneType}}} = this.props;
        let {curPreset} = this.state;
        let curGlobalConfig = this.findCurGlobalConfig();
        for (let i = 0; i < curGlobalConfig.Detail.DetectRegions.length; i++) {
            if (curGlobalConfig.Detail.DetectRegions[i].Number === modifyOrSelectedData.Number) {
                curGlobalConfig.Detail.DetectRegions[i].DetectRegion = modifyOrSelectedData.DetectRegion;
                break;
            }
        }
        if (vm.VRule instanceof Array && vm.VRule.length > 0) {
            for (let i = 0; i < vm.VRule[vm.CurChannel].length; i++) {
                if (vm.VRule[vm.CurChannel][i].PtzPresetId === curPreset && vm.VRule[vm.CurChannel][i].Config.DectRegionNumber === modifyOrSelectedData.Number && vm.VRule[vm.CurChannel][i].Class === sceneType) {
                    vm.VRule[vm.CurChannel][i].Config.DetectRegion = modifyOrSelectedData.DetectRegion;
                }
            }
        }
        change(vm);
        let operatedRow = this.findSelectedRow(modifyOrSelectedData.Number);
        if (this.realForm) {
            this.realForm.handleRowClick(modifyOrSelectedData, operatedRow);
        }
    }
    /**
     * 查找区域号对应的表格行号
     * @param {Number} selectedAreaNumber 选中的区域号
     * @return {Number} selectedRow 对应的表格行号
     */
    findSelectedRow = (selectedAreaNumber) => {
        let curGlobalConfig = this.findCurGlobalConfig();
        const tabData = this.findTabData(curGlobalConfig);
        let selectedRow = 0;
        for (let i = 0; i < tabData.length; i++) {
            if (tabData[i].Number === selectedAreaNumber) {
                selectedRow = i;
                break;
            }
        }
        return selectedRow;
    }
    /**
     * 重新绘制区域
     */
    reDrawArea = () => {
        if (this.plugin.current) {
            this.plugin.current.drawArea();
        }
    }
    /**
     * 删除区域(子组件触发)
     * @param {Object} delData 删除的区域数据
     */
    delteAreaData = (delData) => {
        let {change, IVSTrafficIllegalRegionConfig : {vm}} = this.props;
        let curGlobalConfig = this.findCurGlobalConfig();
        let delAreaIndex = -1;
        for (let i = 0; i < curGlobalConfig.Detail.DetectRegions.length; i++) {
            if (curGlobalConfig.Detail.DetectRegions[i].Number === delData.Number) {
                delAreaIndex = i;
                break;
            }
        }
        if (delAreaIndex === -1) {
            return;
        }
        let isDelLast = false;
        if (delAreaIndex === curGlobalConfig.Detail.DetectRegions.length - 1) {
            isDelLast = true;
        }
        curGlobalConfig.Detail.DetectRegions.splice(delAreaIndex, 1);
        this.deleteRule(delData.Number);
        this.completeDraw = true;
        change(vm);
        this.reDrawArea();
        if (this.realForm && isDelLast) {
            const tabData = this.findTabData(curGlobalConfig);
            this.realForm.handleRowClick(tabData[tabData.length - 1], tabData.length - 1);
        }
    }
    /**
     * 删除区域所对应的规则
     * @param {Number} delAreaNum 删除的区域号
     */
    deleteRule = (delAreaNum) => {
        let {IVSTrafficIllegalRegionConfig : {vm, vm:{sceneType}}} = this.props;
        let {curPreset} = this.state;
        let retainRuleArr = vm.VRule[vm.CurChannel].filter((item, index) => { //没有被删除的规则
            return (item.PtzPresetId !== curPreset || item.Class !== sceneType || item.Config.DectRegionNumber !== delAreaNum);
        });
        let delRuleArr = vm.VRule[vm.CurChannel].filter((item, index) => { //被删除的规则
            return (item.PtzPresetId === curPreset && item.Class === sceneType && item.Config.DectRegionNumber === delAreaNum);
        });
        vm.VRule[vm.CurChannel] = [];
        vm.VRule[vm.CurChannel] = retainRuleArr;
        if (delRuleArr.length > 0) {
            this.delMultiSceneRecordItem(delRuleArr);
            this.delSceneSnapShotWithRule2Item(delRuleArr);
            this.delTrafficImageItem(delRuleArr);
            this.delTrafficSnapMosaic2Item(delRuleArr);
        }
    }

    delMultiSceneRecordItem = (delRuleData) => {
        let {IVSTrafficIllegalRegionConfig : {vm}} = this.props;
        let {curPreset} = this.state;
        let delIndex = 0;
        for (let i = 0; i < vm.MulSceneRec[vm.CurChannel].PresetRecord.length; i++) {
            if (vm.MulSceneRec[vm.CurChannel].PresetRecord[i].PtzPresetId === curPreset) {
                delIndex = i;
                break;
            }
        }

        let retainMulSceneRecArr = vm.MulSceneRec[vm.CurChannel].PresetRecord[delIndex].Records;
        for (let i = 0; i < delRuleData.length; i++) {
            retainMulSceneRecArr = this.filterPublic(retainMulSceneRecArr, delRuleData[i].Id);
        }

        vm.MulSceneRec[vm.CurChannel].PresetRecord[delIndex].Records = [];
        vm.MulSceneRec[vm.CurChannel].PresetRecord[delIndex].Records = retainMulSceneRecArr;
    }

    delSceneSnapShotWithRule2Item = (delRuleData) => {
        let {IVSTrafficIllegalRegionConfig : {vm}} = this.props;
        let {curPreset} = this.state;
        let delIndex = 0;
        for (let i = 0; i < vm.SceneSnapShotWithRule2[vm.CurChannel].length; i++) {
            if (vm.SceneSnapShotWithRule2[vm.CurChannel][i].PresetID === curPreset) {
                delIndex = i;
                break;
            }
        }

        let retainSceneSnapShotWithRule2Arr = vm.SceneSnapShotWithRule2[vm.CurChannel][delIndex].SnapShotWithRule;
        for (let i = 0; i < delRuleData.length; i++) {
            retainSceneSnapShotWithRule2Arr = this.filterPublic(retainSceneSnapShotWithRule2Arr, delRuleData[i].Id);
        }

        vm.SceneSnapShotWithRule2[vm.CurChannel][delIndex].SnapShotWithRule = [];
        vm.SceneSnapShotWithRule2[vm.CurChannel][delIndex].SnapShotWithRule = retainSceneSnapShotWithRule2Arr;
    }

    /**
     * 删除区域所对应的TrafficImage
     * @param {Number} delRuleData 删除的数据
     */
    delTrafficImageItem = (delRuleData) => {
        let {IVSTrafficIllegalRegionConfig : {vm}} = this.props;
        let {curPreset} = this.state;
        let delIndex = 0;
        for (let i = 0; i < vm.TrafficImage[vm.CurChannel].PresetImage.length; i++) {
            if (vm.TrafficImage[vm.CurChannel].PresetImage[i].PtzPresetId === curPreset) {
                delIndex = i;
                break;
            }
        }

        let retainTrafficImageArr = vm.TrafficImage[vm.CurChannel].PresetImage[delIndex].Images;
        for (let i = 0; i < delRuleData.length; i++) {
            retainTrafficImageArr = this.filterPublic(retainTrafficImageArr, delRuleData[i].Id);
        }

        vm.TrafficImage[vm.CurChannel].PresetImage[delIndex].Images = [];
        vm.TrafficImage[vm.CurChannel].PresetImage[delIndex].Images = retainTrafficImageArr;
    }

    /**
     * 删除区域所对应的TrafficSnapMosaic2
     * @param {Number} delRuleData 删除的数据
     */
    delTrafficSnapMosaic2Item = (delRuleData) => {
        let {IVSTrafficIllegalRegionConfig : {vm}} = this.props;
        let {curPreset} = this.state;
        let delIndex = 0;
        for (let i = 0; i < vm.TrafficSnapMosaic2[vm.CurChannel].length; i++) {
            if (vm.TrafficSnapMosaic2[vm.CurChannel][i].PresetID === curPreset) {
                delIndex = i;
                break;
            }
        }

        let retainTrafficSnapMosaic2Arr = vm.TrafficSnapMosaic2[vm.CurChannel][delIndex].MosaicWithRule;
        for (let i = 0; i < delRuleData.length; i++) {
            retainTrafficSnapMosaic2Arr = this.filterPublic(retainTrafficSnapMosaic2Arr, delRuleData[i].Id);
        }

        vm.TrafficSnapMosaic2[vm.CurChannel][delIndex].MosaicWithRule = [];
        vm.TrafficSnapMosaic2[vm.CurChannel][delIndex].MosaicWithRule = retainTrafficSnapMosaic2Arr;
    }

    filterPublic = (arr, id) => {
        return arr.filter((item, index) => {
            return (item.RuleId !== id);
        });
    }
    /**
     * 改变区域名称(子组件触发)
     * @params {Number} modifyAreaNameNumber 修改名字的区域的区域号
     * @params {String} modifyName 修改后的名字
     */
    changeAreaName = (modifyAreaNameNumber, modifyName) => {
        let {change, IVSTrafficIllegalRegionConfig : {vm, vm:{sceneType}}} = this.props;
        let {curPreset} = this.state;
        if (vm.VRule instanceof Array && vm.VRule.length > 0) {
            for (let i = 0; i < vm.VRule[vm.CurChannel].length; i++) {
                if (vm.VRule[vm.CurChannel][i].PtzPresetId === curPreset && vm.VRule[vm.CurChannel][i].Config.DectRegionNumber === modifyAreaNameNumber && vm.VRule[vm.CurChannel][i].Class === sceneType) {
                    vm.VRule[vm.CurChannel][i].Name = modifyName;
                }
            }
        }
        change(vm);
    }

    /**
     * 页面“应用/下一步”按钮触发
     */
    onConfirm () {
        let { save, $t, IVSTrafficIllegalRegionConfig : {vm}, currentPlanID } = this.props;
        if (!this.completeDraw) {
            message.error($t('com.PleaseDrawShapeTip'));
            return;
        }
        save(currentPlanID) //保存
            .then(() => {
                this.reDrawArea();
                message.success($t('com.Operateingsuccess'));
            })
            .catch(() => {
                message.error($t('com.Operateingfailure'));
            });
    }

    /**
     * 页面“刷新”按钮触发
     * @param {Boolean} flag 是否是刷新按钮触发
     */
    onRefresh (flag) {
        let { refresh, $t, currentPlanID } = this.props;
        let curGlobalConfig = this.findCurGlobalConfig();
        refresh(currentPlanID) //刷新
            .then(() => {
                this.completeDraw = true;
                this.reDrawArea();
                if (this.realForm) {
                    this.realForm.handleRowClick(this.findTabData(curGlobalConfig)[0], 0);
                }
                flag && message.success($t('com.Operateingsuccess'));
            })
            .catch(() => {
                message.error($t('com.Operateingfailure'));
            });
    }
    /**
     * 恢复默认
     */
    onDefault () {
        let { onDefault, $t, IVSTrafficIllegalRegionConfig : {vm} } = this.props;
        onDefault() //恢复默认
            .then(() => {
                //清空区域下的规则
                if (vm.defaultCleanAreaNumberaArr instanceof Array && vm.defaultCleanAreaNumberaArr.length > 0) {
                    for (let i = 0; i < vm.defaultCleanAreaNumberaArr.length; i++) {
                        let tmpObj = {};
                        tmpObj.Number = vm.defaultCleanAreaNumberaArr[i];
                        this.delteAreaData(tmpObj);
                    }
                }
                this.completeDraw = true;
                this.reDrawArea();
                message.success($t('com.OperateingSuccessTip'));
            })
            .catch(() => {
                message.error($t('com.Operateingfailure'));
            });
    }
    /**
     * 查找预置点名称
     * @param {Number} presetID 预置点编号
     */
    findPresetName = (presetID) => {
        let {IVSTrafficIllegalRegionConfig : {vm}} = this.props;
        let presetName = '';
        for (let i = 0; i < vm.presets.length; i++) {
            if (vm.presets[i].Index === presetID) {
                presetName = vm.presets[i].Name;
                break;
            }
        }
        return presetName;
    }

    formChange = (filed) => {
        let {change, IVSTrafficIllegalRegionConfig : {vm}, $t} = this.props;
        let curGlobalConfig = this.findCurGlobalConfig();
        let curCalibrateConfig = this.findCurCalibrateConfig();
        for (let item in filed) {
            switch (item) {
            case 'horizontalRate':
                curCalibrateConfig.CalibrateArea[0].ObjectScale = filed[item].value - 0;
                break;
            case 'TargetLicensePlateSize':
                curCalibrateConfig.CalibratePixel = filed[item].value - 0;
                break;
            case 'ReportUnlicensedVehicle':
                curGlobalConfig.Detail.ReportMode = filed[item].value ? 'all' : 'plate';
                break;
            case 'LicensePlateMatchingRate':
                curGlobalConfig.Detail.PlateMatch = filed[item].value - 0;
                break;
            case 'LocalWord':
                curGlobalConfig.Detail.PlateHints = (filed[item].value === $t('com.None') ? [] : [`${filed[item].value}`]);
                break;
            default:
                break;
            }
        }
        change(vm);
    }

    clickReDrawArea = () => {
        if (!this.completeDraw) {
            return;
        }
        let {selectRow} = this.state;
        if (this.plugin.current) {
            this.completeDraw = false;
            this.plugin.current.rePluginDrawArea(selectRow);
        }
    }

    render () {
        let {$t, langlocale, publicState, IVSTrafficIllegalRegionConfig : {vm, caps, vm:{sceneType}}} = this.props;
        let {curPreset, selectRow, selectNumber, channelOptions, presetOptions} = this.state;
        let curGlobalConfig = this.findCurGlobalConfig();
        let curCalibrateConfig = JSON.stringify(vm.TemplateCalibrate) !== '{}' ? this.findCurCalibrateConfig() : null;
        const multiple = {
            type: 'normal',
            multiple: 1
        };
        const englishColWidth = [{span : 9, xxl : 6}, {span : 9, xxl : 9}, {span : 5, xxl : 5, offset : 0}];
        let layOutColWidth = (langlocale.locale === 'zh-cn') ? publicState.getGrid(multiple).colWidth : englishColWidth;
        let ReDrawflag = false;
        if (curGlobalConfig && curGlobalConfig.Detail && curGlobalConfig.Detail.DetectRegions) {
            for (var i = 0; i < curGlobalConfig.Detail.DetectRegions.length; i++) {
                let itemValCopy = curGlobalConfig.Detail.DetectRegions[i];
                //新增字段类型区分
                if (itemValCopy.SceneDetectRegionType === undefined || (itemValCopy.SceneDetectRegionType !== undefined && itemValCopy.SceneDetectRegionType[0] === sceneType)) {
                    ReDrawflag = true;
                }
            }
        }
        let showButton = WebApp.DeviceType.indexOf('SD') > -1 ? (presetOptions && presetOptions.length > 0 ? '' : 'none') : ''; //是否显示按钮（因点击“全局方案”中的“违停手动取证”进入时，是不可操作“区域配置”页面）
        return (
            <div className={`_${_name_}_`}>
                {WebApp.CHANNEL_NUMBER > 1 || (WebApp.DeviceType.indexOf('SD') > -1 && presetOptions && presetOptions.length > 0) ? (
                    <div className='topContent'>
                        <Row>
                            {WebApp.CHANNEL_NUMBER > 1 ? (
                                <Col span={8}>
                                    <LabelSelect
                                        label={$t('com.Channel')}
                                        controlWidth={[5, 12]}
                                        options={channelOptions}
                                        onChange={this.changeChannel}
                                        onDropdownVisibleChange={this.dropdownVisibleChange}
                                        value={vm.CurChannel}
                                    />
                                </Col>)
                                : null}
                            {WebApp.DeviceType.indexOf('SD') > -1 && presetOptions && presetOptions.length > 0 ? (
                                <Col span={8}>
                                    <LabelSelect
                                        label={$t('per.Preset')}
                                        controlWidth={[5, 12]}
                                        options={presetOptions}
                                        onChange={this.changePreset}
                                        onDropdownVisibleChange={this.dropdownVisibleChange}
                                        value={curPreset}
                                    />
                                </Col>)
                                : null}
                        </Row>
                    </div>)
                    : null
                }
                <div style={{ borderBottom: '1px solid #e8e8e8' }}></div>
                <div className = 'Content'>
                    <Row>
                        <Col span={12} className='fn-wid470'>
                            <Row>
                                <TrafficIllegalRegionConfigurationPlugin
                                    ref={this.plugin}
                                    curGlobalConfig={curGlobalConfig}
                                    selectRow={selectRow}
                                    addAreaData={this.addAreaData}
                                    modifyOrSelectedAreaData={this.modifyOrSelectedAreaData}
                                    selectNumber={selectNumber}
                                    curChannel={vm.CurChannel}
                                    curPreset={curPreset}
                                    curPtzName={this.findPresetName(curPreset)}
                                    sceneType={sceneType}
                                />
                            </Row>
                            <Row style = {{display : `${showButton}`}}>
                                <div className='operation'>
                                    <Button
                                        className='buttonGroup-preButton label-behind-dark'
                                        onClick={this.clickAddArea}
                                        disabled={(curGlobalConfig.Detail && curGlobalConfig.Detail.DetectRegions instanceof Array) ? curGlobalConfig.Detail.DetectRegions.length >= caps.maxDetectRegionNum : false}
                                    >
                                        <Icon type="irregularity" />
                                        <span className="label-behind-dark fn-marl5" title={$t('ivs.DrawRegion')}>{$t('ivs.DrawRegion')}</span>
                                    </Button>
                                    {ReDrawflag && this.findTabData(curGlobalConfig)[selectRow]?.Enable &&
                                        <Button
                                            onClick={this.clickReDrawArea}
                                            className="fn-right"
                                        >
                                            {$t('pfm.Repaint')}
                                        </Button>
                                    }
                                </div>
                            </Row>
                        </Col>
                        <Col span={12}>
                            <div className="config-wrap">
                                <TrafficIllegalRegionConfigurationConfigForm
                                    curGlobalConfig={curGlobalConfig}
                                    setCurAreaRow={this.setCurAreaRow}
                                    showAreaInPlugin={this.showAreaInPlugin}
                                    wrappedComponentRef={(inst) => this.realForm = inst}
                                    delteAreaData={this.delteAreaData}
                                    changeAreaName={this.changeAreaName}
                                    formChange={this.formChange}
                                    curCalibrateConfig={curCalibrateConfig}
                                    colWidth={layOutColWidth}
                                    lang={langlocale.locale}
                                    sceneType={sceneType}
                                />
                                <div className="operation">
                                    <Button type="primary" className="fn-marr10 _submitButton" onClick = {() => this.onConfirm()}>{$t('com.Apply')}</Button>
                                    <Button className="fn-marr10 _refreshButton" onClick = {() => this.onRefresh(true)}>{$t('com.Refresh')}</Button>
                                    <Button className="fn-marr10 _defaultButton" onClick = {() => this.onDefault()}>{$t('com.Comdefault')}</Button>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>
        );
    }
}

export default connect(mapStateToProps,mapDispatchToProps,null,{forwardRef:true})(LangConsumer(bindLifecycle(TrafficIllegalRegionConfigurationComponent)));